<template>
  <time-picker class="start-picker" :for-booking="forBooking" @start-picked="onStartPicked" :datetime="datetime" :min-date="minDate" :availabilities="availabilities" @reload-availabilities="reloadAvailabilities" :show="show" format-string="yyyy-MM-dd HH:mm" :loading="loading"></time-picker>
</template>
<script>
import TimePicker from './time_picker.vue'
import AvailabilitiesMixin from './availabilities_mixin.js'
import StartPickerMixin from './start_picker_mixin.js'
import StartAndStatusInputInterfaceMixin from './start_and_status_input_interface_mixin.js'

export default {
  mixins: [AvailabilitiesMixin, StartPickerMixin, StartAndStatusInputInterfaceMixin],
  components: { TimePicker }
}
</script>
